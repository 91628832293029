// /* General */
$fontSize:15px;
$bodyBgColor:#f8f8f8;
$textColor:#333333;
// $textSecondaryColor:#707070;
// $borderRadius:3px;
// $dividerColor:#e3e3e3;
// $transitionDuration:.2s;
// $maskBgColor:#424242;

// /* AppContent Common */
// $menuitemBadgeBgColor:#007be5;
// $menuitemBadgeColor:#ffffff;
// $submenuFontSize:13px;
// $menuitemActiveRouteColor:#1fa1fc;

// /* AppContent Light */
// $menuBgColorFirst:#ffffff;
// $menuBgColorLast:#ffffff;
// $menuitemColor:#3F434A;
// $menuitemHoverColor:#3F434A;
// $menuitemActiveColor:#304FFD;
// $menuitemActiveBgColor:#ffffff;
// $menuitemBorderColor:rgba(255, 255, 255, 0.6);

// /* AppContent Dark */
// $menuDarkBgColorFirst:#4d505b;
// $menuDarkBgColorLast:#3b3e47;
// $menuitemDarkColor:#ffffff;
// $menuitemDarkHoverColor:#0388e5;
// $menuitemDarkActiveColor:#0388e5;
// $menuitemDarkActiveBgColor:#2e3035;
// $menuitemDarkBorderColor:rgba(52, 56, 65, 0.6);

// /* Topbar */
// $topbarLeftBgColor:#457fca;
// $topbarRightBgColor:#457fca;
// $topbarItemBadgeBgColor:#ef6262;
// $topbarItemBadgeColor:#ffffff;
// $topbarItemColor:#44566C;
// $topbarItemHoverColor:#2c3947;
// $topbarSearchInputBorderBottomColor:#ffffff;
// $topbarSearchInputColor:#ffffff;

// /* Footer */
// $footerBgColor:#ffffff;

/* Login */
$buttonLogin:#035AC9;
$bemVindoText:#035AC9;

$grey: #C6C9CC;
$darkBlue: #009EB3;


$heading: #3F434A;
$bodyLight: #8A9099;
// $bodyDark: #595F69;
$border: #E8E9EB;
$bg: #F8F8F8;
$accent: #304FFD;
// $orange: #FF965D;
$red: #FD7972;
// $yellow: #FFD240;
// $blue: #22CCE2;
// $green: #49C96D;
// $darkGreen: #20A144;
$darkOrange: #E56E19;
// $darkYellow: #E6B823;
$darkRed: #F05C54;
// $purple: #B656EB;
// $pink: #F9DDC5;