@import "../_variables";
.login-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #cccccc;

  .login-body {
    height: 100vh;
    display: flex;
    align-items: stretch;
    flex-direction: column;

    @media (min-width: 950px) {
      flex-direction: row;
    }

    .login-first-area {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-direction: column;
    }

    .login-logo {
      height: 100%;

      .logo-body {
        .logo-welcome {
          color: #353C46;
          font-size: 2.4rem;
          font-weight: 700;
          text-align: center;

          @media (max-width: 1500px) {
            font-size: 2rem;
          }

          &.sub-text {
            font-size: 1.3rem !important;

            @media (max-width: 1500px) {
              font-size: 1.1rem !important;
            }

            font-weight: 600 !important;
            text-decoration: underline !important;
            color: #686C72;
            margin-bottom: 50px;
          }
        }

        .border-1 {
          border-width: 0px !important;
          border-style: solid !important;
        }

        .p-carousel-next {
          display: none;
        }

        .p-carousel-prev {
          display: none;
        }

        .p-carousel-indicators {
          display: none;
        }

        .carousel-name {
          color: #263238;
        }

        .homem-na-poltrona {
          height: 284px !important;

          @media (max-width: 1500px) {
            height: 240px !important;
          }
        }

        display: flex;
        flex-direction: column;
        justify-content: center;
        border-right: 6px solid #43FFC41F;
        height: 80%;
      }

      .logo {
        align-items: center;
        margin-left: 50px;
        margin-top: 40px;
      }
    }

    .login-welcome {
      font-size: 1.5rem;
      color: $bemVindoText;
      font-weight: 700;
    
      @media (max-width: 1500px) {
        font-size: 1.3rem;
      }
    
      @media (max-width: 768px) {
        font-size: 1rem;
      }
    }
    .login-small {
      color:$grey;
      font-size: 0.6rem;
      font-weight: 300;
    }    

    .login-form {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 15vh 150px;


      @media (max-width: 768px) {
        padding: 40px;
        padding-top: 0px;
        height: 100%;
      }

      .icone-login {
        @media (max-width: 768px) {
          margin-top: 60px;
          margin-bottom: 0px !important;
          height: 10%;
        }
      }
    }    
  }
}

.login-button {
  background-color: $buttonLogin !important;
  border-bottom: none !important;

  .p-button-label {
      font-size: 1.3rem;
      font-weight: 500;
      margin-right: 30px;
  }

  &:hover {
        background-color: #272c33 !important;
  }
}